import { Link } from 'gatsby'
import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'

// http://www.dktech.cz/?page_id=34

const StatistikyServeru: FC = () => {
  return (
    <PageTemplate>
      <div className="topTitle">
        <h1><Link to="/statistiky_serveru">Statistiky serveru</Link></h1>
      </div>
      <p><a title="Top 20 hráčů, Top 10 klanů" href="http://185.5.227.206/statistics/general.html" target="_blank"><strong>Top 20 hráčů, Top 10 klanů</strong></a></p>
      <p><strong><a title="Castles" href="http://185.5.227.206/statistics/castles.html" target="_blank">Hrady</a></strong></p>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default StatistikyServeru

export { Head } from '../components/defaultHtmlHead'